<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{$t('cost_centers.cost_centers')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <div>
                        <b-dropdown right :text="$t('extra_options')">
                            <b-dropdown-item v-if="$can('cost_centers.all_transactions')" @click="allTransactions">{{$t('all_transactions')}}</b-dropdown-item>
                            <b-dropdown-item v-if="$can('accounts_without_cost_center.list')" @click="accountsWithoutCostCenter">{{$t('MENU.accounts_without_cost_center')}}</b-dropdown-item>
                            <b-dropdown-item v-if="$can('accounts_with_cost_center.list')" @click="accountsWithCostCenter">{{$t('MENU.accounts_with_cost_center')}}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>

            <div class="card-body p-0">
                <div class="d-flex">
                    <b-card-body class="col-md-4">
                        <div class="col-lg-12 mb-5 p-0">
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="search" :placeholder="$t('search')"/>
                            </div>
                            <div class="w-100 mt-4">
                                <button class="btn btn-primary btn-sm text-center w-100" @click="getSubList(null)">{{$t('cost_centers.main')}}</button>
                            </div>
                        </div>
                        <v-treeview
                                :items="tree_list"
                                :search="search"
                                :filter="filter"
                                return-object
                                selected-color="warning"
                                :selection-type="selectionType"
                                hoverable
                                expand-icon="mdi-chevron-down"
                                on-icon="mdi-bookmark"
                                off-icon="mdi-bookmark-outline"
                                indeterminate-icon="mdi-bookmark-minus"
                                :active.sync="item"
                                activatable
                        >
                            <template v-slot:prepend="{ item, open }">
                                <v-icon v-if="!item.file">
                                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                                </v-icon>
                                <v-icon v-else>
                                    {{ files[item.file] }}
                                </v-icon>
                            </template>
                        </v-treeview>
                    </b-card-body>
                    <b-card-body class="col-md-8">
                        <div class="form-group overflow-y-auto" style="height: 300px;overflow-y: scroll;">
                            <table class="table border">
                                <tbody>
                                    <thead>
                                        <tr>
                                            <th width="9%"></th>
                                            <!-- <th width="2%"></th> -->
                                            <th width="5%">{{$t('cost_centers.debit')}}</th>
                                            <th width="10%">{{$t('cost_centers.credit')}}</th>
                                        </tr>
                                    </thead>
                                <tr v-for="row in sub_List" :key="row.id">
                                    <td scope="row">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <b-icon icon="folder" font-scale="3"></b-icon>
                                            </div>
                                            <div style="width: 100%;padding-left: 28px;padding-right: 28px;">
                                                <div class="row d-flex justify-content-between"><span><a href="javascript:;" @click="getSubList(row.id) ">{{row.name}}</a></span> <span></span></div>
                                                <div class="row d-flex justify-content-between">
                                                    <span>#{{row.code}}<br></span>
                                                    <span>{{$t('cost_centers.sales_invoice')}} #{{ row.sales_invoice ? row.sales_invoice.id : '' }}</span>
                                                </div>
                                            </div>
                                            <div style="width: 100%;padding-left: 28px;padding-right: 28px;">
                                                0
                                            </div>
                                            <div style="width: 100%;padding-left: 28px;padding-right: 28px;">
                                                {{ row.sales_invoice ? row.sales_invoice.invoice_total : 0 }}
                                            </div>
                                            <div>
                                                <b-dropdown variant="dark" class="btn-sm">
                                                    <template #button-content>
                                                        <b-icon icon="gear-fill" aria-hidden="true" font-scale="1"></b-icon>
                                                    </template>

                                                    <b-dropdown-item-button v-if="$can('cost_centers.update')" variant="primary" @click="editItem(row)">
                                                        <b-icon icon="pencil" aria-hidden="true"></b-icon>
                                                        {{$t('edit')}}
                                                    </b-dropdown-item-button>

                                                    <b-dropdown-item-button v-if="$can('cost_centers.delete')" variant="danger" @click="deleteItem(row)">
                                                        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                                        {{$t('delete')}}
                                                    </b-dropdown-item-button>
                                                </b-dropdown>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="sub_List.length <= 0">
                                    <td scope="row">
                                        <h5>{{$t('cost_centers.this_parent_cost_center_is_empty_without_childs')}}</h5>
                                    </td>
                                </tr>
                                <tr v-if="$can('cost_centers.create')">
                                    <td scope="row">
                                        <router-link  :to="'/accounting/cost-centers/create'+(parent_id? '/'+parent_id: '')"  class="font-weight-bolder text-primary"><v-icon>mdi-plus</v-icon> {{ $t('cost_centers.new_chart_account') }}</router-link>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </b-card-body>
                </div>


            </div>

        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-cost-centers",
        data() {
            return {
                mainRoute: 'accounting/cost-centers',
                item: [],
                selectionType: 'independent',

                tree_list: [],
                sub_List: [],
                search: '',
                parent_id: null,
            };
        },
        computed: {
            filter() {
                return (item, search, textKey) => item[textKey].indexOf(search) > -1
            },
        },
        watch: {
            item: function (val) {
                this.sub_List = [];
                if (val[0]) {
                    val = val[0];
                    this.getSubList(val.id);
                }
            }
        },
        methods: {
            allTransactions(){
                this.$router.push({name: 'cost-centers.transactions'});
            },
            accountsWithoutCostCenter(){
                this.$router.push({name: 'accounts-without-cost-center.list'});
            },
            accountsWithCostCenter(){
                this.$router.push({name: 'accounts-with-cost-center.list'});
            },

            getList() {
                ApiService.get(this.mainRoute).then((response) => {
                    this.tree_list = response.data.data;
                });
            },

            getSubList(parent_id = null) {
                this.parent_id = parent_id;
                ApiService.get(this.mainRoute,{params: {parent_cost_center_id: parent_id, is_children: (parent_id?1:0)}}).then((response) => {
                    this.sub_List = response.data.data;
                });
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getList();
                    this.getSubList(this.parent_id);
                    this.$successAlert(response.data.message);
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            editItem(item) {
                this.$router.push({name: 'cost-centers.edit', params: {id: item.id}});
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.cost_centers")}]);
            this.getList();
        },
    };
</script>

<style>
    .v-treeview-node__root {
        min-height: 37px !important;
        cursor: pointer;
    }

    .v-treeview-node--active {
        background-color: #C9F7F5;
    }
</style>
